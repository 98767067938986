import { useState, useEffect, useCallback } from 'react';

// Language names mapping
export const languageNames = {
  English: "English",
  Spanish: "Español",
  French: "Français",
  German: "Deutsch",
  Italian: "Italiano",
  Japanese: "日本語",
  Chinese: "中文",
  Russian: "Русский",
  Portuguese: "Português",
  Dutch: "Nederlands",
  Korean: "한국어",
  Arabic: "العربية",
  Hindi: "हिन्दी",
  Swedish: "Svenska",
  Polish: "Polski",
  Turkish: "Türkçe",
  Danish: "Dansk",
  Norwegian: "Norsk",
  Finnish: "Suomi",
  Greek: "Ελληνικά"
};

// Existing button translations
export const buttonTranslations = {
  login: {
    English: "Login",
    Spanish: "Iniciar sesión",
    French: "Connexion",
    German: "Anmelden",
    Italian: "Accedi",
    Japanese: "ログイン",
    Chinese: "登录",
    Russian: "Вход",
    Portuguese: "Entrar",
    Dutch: "Inloggen",
    Korean: "로그인",
    Arabic: "تسجيل الدخول",
    Hindi: "लॉग इन करें",
    Swedish: "Logga in",
    Polish: "Zaloguj się",
    Turkish: "Giriş yap",
    Danish: "Log ind",
    Norwegian: "Logg inn",
    Finnish: "Kirjaudu sisään",
    Greek: "Σύνδεση"
  },
  logout: {
    English: "Logout",
    Spanish: "Cerrar sesión",
    French: "Déconnexion",
    German: "Abmelden",
    Italian: "Esci",
    Japanese: "ログアウト",
    Chinese: "登出",
    Russian: "Выход",
    Portuguese: "Sair",
    Dutch: "Uitloggen",
    Korean: "로그아웃",
    Arabic: "تسجيل الخروج",
    Hindi: "लॉग आउट करें",
    Swedish: "Logga ut",
    Polish: "Wyloguj się",
    Turkish: "Çıkış yap",
    Danish: "Log ud",
    Norwegian: "Logg ut",
    Finnish: "Kirjaudu ulos",
    Greek: "Αποσύνδεση"
  },
  subscribe: {
    English: "Subscribe",
    Spanish: "Suscribirse",
    French: "S'abonner",
    German: "Abonnieren",
    Italian: "Iscriviti",
    Japanese: "購読",
    Chinese: "订阅",
    Russian: "Подписаться",
    Portuguese: "Inscrever-se",
    Dutch: "Abonneren",
    Korean: "구독하기",
    Arabic: "اشترك",
    Hindi: "सदस्यता लें",
    Swedish: "Prenumerera",
    Polish: "Subskrybuj",
    Turkish: "Abone ol",
    Danish: "Abonner",
    Norwegian: "Abonner",
    Finnish: "Tilaa",
    Greek: "Εγγραφή"
  },
  subscribing: {
    English: "Subscribing...",
    Spanish: "Suscribiendo...",
    French: "S'abonnant...",
    German: "Abonnieren...",
    Italian: "Iscrizione in corso...",
    Japanese: "購読中...",
    Chinese: "订阅中...",
    Russian: "Подписка...",
    Portuguese: "Inscrevendo-se...",
    Dutch: "Aan het abonneren...",
    Korean: "구독 중...",
    Arabic: "مشترك...",
    Hindi: "सदस्यता ले रहा है...",
    Swedish: "Prenumererar...",
    Polish: "Subskrybując...",
    Turkish: "Abonelik oluşturuluyor...",
    Danish: "Abonnerer...",
    Norwegian: "Abonnerer...",
    Finnish: "Tilaaminen...",
    Greek: "Εγγραφή..."
  },
  updatesAndWeeklyReadings: {
    English: "Updates & Weekly Readings",
    Spanish: "Actualizaciones y Lecturas Semanales",
    French: "Mises à jour et Lectures Hebdomadaires",
    German: "Aktualisierungen und Wöchentliche Lesungen",
    Italian: "Aggiornamenti e Letture Settimanali",
    Japanese: "アップデート&週間の読書",
    Chinese: "更新&每周读物",
    Russian: "Обновления и Еженедельные Чтения",
    Portuguese: "Atualizações e Leituras Semanais",
    Dutch: "Updates en Wekelijkse Lezingen",
    Korean: "업데이트&주간 독서",
    Arabic: "المستجدات والمقالات الأسبوعية",
    Hindi: "अपडेट और साप्ताहिक पठन",
    Swedish: "Uppdateringar och Veckoläsningar",
    Polish: "Aktualizacje i Czytania Tygodniowe",
    Turkish: "Güncellemeler ve Haftalık Okumalar",
    Danish: "Opdateringer og Ugentlige Læsninger",
    Norwegian: "Oppdateringer og Ukentlige Lesninger",
    Finnish: "Päivitykset ja Viikoittaiset Lukemiset",
    Greek: "Ενημερώσεις και Εβδομαδιαίες Αναγνώσεις"
  },
  signupDescription: {
    English: "Signup to receive updates about Tarotmancer, and receive a weekly reading delivered to your email.",
    Spanish: "Regístrese para recibir actualizaciones sobre Tarotmancer y recibir una lectura semanal en su correo electrónico.",
    French: "Inscrivez-vous pour recevoir des mises à jour sur Tarotmancer et recevez une lecture hebdomadaire envoyée à votre adresse e-mail.",
    German: "Melden Sie sich an, um Updates über Tarotmancer zu erhalten und eine wöchentliche Lesung per E-Mail zu erhalten.",
    Italian: "Iscrivetevi per ricevere aggiornamenti su Tarotmancer e ricevere una lettura settimanale inviata alla vostra email.",
    Japanese: "Tarotmancerのアップデートを受け取るには、週間の読書をメールで受け取るには、サインアップしてください。",
    Chinese: "注册以接收Tarotmancer的更新,并通过电子邮件接收每周的读物。",
    Russian: "Зарегистрируйтесь, чтобы получать обновления о Tarotmancer и получать еженедельное чтение по электронной почте.",
    Portuguese: "Inscreva-se para receber atualizações sobre o Tarotmancer e receber uma leitura semanal enviada por e-mail.",
    Dutch: "Schrijf je in om updates over Tarotmancer te ontvangen en een wekelijkse lezing per e-mail te ontvangen.",
    Korean: "Tarotmancer 업데이트를 받고 주간 독서를 이메일로 받으려면 가입하세요.",
    Arabic: "سجل الآن لكي تحصل على التحديثات الخاصة بـ Tarotmancer وتحصل على قراءة أسبوعية ترسل إلى بريدك الإلكتروني.",
    Hindi: "Tarotmancer के अपडेट प्राप्त करने और अपने ईमेल पर साप्ताहिक पठन प्राप्त करने के लिए साइन अप करें।",
    Swedish: "Registrera dig för att få uppdateringar om Tarotmancer och få en veckoläsning skickad till din e-post.",
    Polish: "Zarejestruj się, aby otrzymywać aktualizacje o Tarotmancer i otrzymywać cotygodniowe czytania na swój adres e-mail.",
    Turkish: "Tarotmancer güncellemelerini almak ve e-posta adresinize haftalık okumalar almak için kaydolun.",
    Danish: "Tilmeld dig for at modtage opdateringer om Tarotmancer og modtage en ugentlig læsning sendt til din e-mail.",
    Norwegian: "Registrer deg for å motta oppdateringer om Tarotmancer og motta en ukentlig lesning sendt til din e-post.",
    Finnish: "Rekisteröidy saadaksesi Tarotmancerin päivitykset ja viikoittaiset lukemiset sähköpostiisi.",
    Greek: "Εγγραφείτε για να λαμβάνετε ενημερώσεις για το Tarotmancer και να λαμβάνετε εβδομαδιαία ανάγνωση μέσω ηλεκτρονικού ταχυδρομείου."
  },
  firstName: {
    English: "First name",
    Spanish: "Nombre",
    French: "Prénom",
    German: "Vorname",
    Italian: "Nome",
    Japanese: "名",
    Chinese: "名字",
    Russian: "Имя",
    Portuguese: "Nome",
    Dutch: "Voornaam",
    Korean: "이름",
    Arabic: "الاسم الأول",
    Hindi: "प्रथम नाम",
    Swedish: "Förnamn",
    Polish: "Imię",
    Turkish: "Adı",
    Danish: "Fornavn",
    Norwegian: "Fornavn",
    Finnish: "Etunimi",
    Greek: "Όνομα"
  },
  email: {
    English: "Email",
    Spanish: "Correo electrónico",
    French: "Adresse e-mail",
    German: "E-Mail",
    Italian: "Email",
    Japanese: "メールアドレス",
    Chinese: "电子邮件",
    Russian: "Электронная почта",
    Portuguese: "E-mail",
    Dutch: "E-mail",
    Korean: "이메일",
    Arabic: "البريد الإلكتروني",
    Hindi: "ईमेल",
    Swedish: "E-post",
    Polish: "Adres e-mail",
    Turkish: "E-posta",
    Danish: "E-mail",
    Norwegian: "E-post",
    Finnish: "Sähköposti",
    Greek: "Διεύθυνση ηλεκτρονικού ταχυδρομείου"
  },
  signUp: {
    English: "Sign up",
    Spanish: "Registrarse",
    French: "S'inscrire",
    German: "Anmelden",
    Italian: "Iscriviti",
    Japanese: "登録",
    Chinese: "注册",
    Russian: "Зарегистрироваться",
    Portuguese: "Inscrever-se",
    Dutch: "Aanmelden",
    Korean: "가입하기",
    Arabic: "سجل الآن",
    Hindi: "सदस्यता लें",
    Swedish: "Registrera dig",
    Polish: "Zarejestruj się",
    Turkish: "Kaydol",
    Danish: "Tilmeld dig",
    Norwegian: "Registrer deg",
    Finnish: "Rekisteröidy",
    Greek: "Εγγραφή"
  },
  sendFeedback: {
    English: "Send Feedback",
    Spanish: "Enviar Comentarios",
    French: "Envoyer des Commentaires",
    German: "Feedback Senden",
    Italian: "Invia Feedback",
    Japanese: "フィードバックを送信",
    Chinese: "发送反馈",
    Russian: "Отправить Отзыв",
    Portuguese: "Enviar Feedback",
    Dutch: "Feedback Versturen",
    Korean: "피드백 보내기",
    Arabic: "إرسال التعليقات",
    Hindi: "प्रतिक्रिया भेजें",
    Swedish: "Skicka Feedback",
    Polish: "Wyślij Opinię",
    Turkish: "Geri Bildirim Gönder",
    Danish: "Send Feedback",
    Norwegian: "Send Tilbakemelding",
    Finnish: "Lähetä Palautetta",
    Greek: "Αποστολή Σχολίων"
  },
  submitFeedback: {
    English: "Submit Feedback",
    Spanish: "Enviar Comentarios",
    French: "Soumettre des Commentaires",
    German: "Feedback Einreichen",
    Italian: "Invia Feedback",
    Japanese: "フィードバックを提出",
    Chinese: "提交反馈",
    Russian: "Отправить Отзыв",
    Portuguese: "Enviar Feedback",
    Dutch: "Feedback Indienen",
    Korean: "피드백 제출",
    Arabic: "تقديم التعليقات",
    Hindi: "प्रतिक्रिया जमा करें",
    Swedish: "Skicka In Feedback",
    Polish: "Prześlij Opinię",
    Turkish: "Geri Bildirimi Gönder",
    Danish: "Indsend Feedback",
    Norwegian: "Send Inn Tilbakemelding",
    Finnish: "Lähetä Palaute",
    Greek: "Υποβολή Σχολίων"
  },
  deleteDraw: {
    English: "Delete Draw",
    Spanish: "Eliminar Tirada",
    French: "Supprimer le Tirage",
    German: "Legung Löschen",
    Italian: "Elimina Stesura",
    Japanese: "ドローを削除",
    Chinese: "删除抽牌",
    Russian: "Удалить Расклад",
    Portuguese: "Excluir Tiragem",
    Dutch: "Legging Verwijderen",
    Korean: "드로우 삭제",
    Arabic: "حذف السحب",
    Hindi: "ड्रा हटाएं",
    Swedish: "Radera Dragning",
    Polish: "Usuń Rozkład",
    Turkish: "Çekimi Sil",
    Danish: "Slet Trækning",
    Norwegian: "Slett Trekk",
    Finnish: "Poista Nosto",
    Greek: "Διαγραφή Τραβήγματος"
  },
  confirm: {
    English: "Confirm",
    Spanish: "Confirmar",
    French: "Confirmer",
    German: "Bestätigen",
    Italian: "Conferma",
    Japanese: "確認",
    Chinese: "确认",
    Russian: "Подтвердить",
    Portuguese: "Confirmar",
    Dutch: "Bevestigen",
    Korean: "확인",
    Arabic: "تأكيد",
    Hindi: "पुष्टि करें",
    Swedish: "Bekräfta",
    Polish: "Potwierdź",
    Turkish: "Onayla",
    Danish: "Bekræft",
    Norwegian: "Bekreft",
    Finnish: "Vahvista",
    Greek: "Επιβεβαίωση"
  },
  cancel: {
    English: "Cancel",
    Spanish: "Cancelar",
    French: "Annuler",
    German: "Abbrechen",
    Italian: "Annulla",
    Japanese: "キャンセル",
    Chinese: "取消",
    Russian: "Отмена",
    Portuguese: "Cancelar",
    Dutch: "Annuleren",
    Korean: "취소",
    Arabic: "إلغاء",
    Hindi: "रद्द करें",
    Swedish: "Avbryt",
    Polish: "Anuluj",
    Turkish: "İptal",
    Danish: "Annuller",
    Norwegian: "Avbryt",
    Finnish: "Peruuta",
    Greek: "Ακύρωση"
  },
  close: {
    English: "Close",
    Spanish: "Cerrar",
    French: "Fermer",
    German: "Schließen",
    Italian: "Chiudi",
    Japanese: "閉じる",
    Chinese: "关闭",
    Russian: "Закрыть",
    Portuguese: "Fechar",
    Dutch: "Sluiten",
    Korean: "닫기",
    Arabic: "إغلاق",
    Hindi: "बंद करें",
    Swedish: "Stäng",
    Polish: "Zamknij",
    Turkish: "Kapat",
    Danish: "Luk",
    Norwegian: "Lukk",
    Finnish: "Sulje",
    Greek: "Κλείσιμο"
  },
  viewPastDraws: {
    English: "View Past Draws",
    Spanish: "Ver Tiradas Anteriores",
    French: "Voir les Tirages Précédents",
    German: "Frühere Legungen Ansehen",
    Italian: "Vedi Stesure Precedenti",
    Japanese: "過去のドローを見る",
    Chinese: "查看历史抽牌",
    Russian: "Посмотреть Прошлые Расклады",
    Portuguese: "Ver Tiragens Anteriores",
    Dutch: "Bekijk Vorige Leggingen",
    Korean: "이전 드로우 보기",
    Arabic: "عرض السحوبات السابقة",
    Hindi: "पिछले ड्रा देखें",
    Swedish: "Visa Tidigare Dragningar",
    Polish: "Zobacz Poprzednie Rozkłady",
    Turkish: "Geçmiş Çekimleri Görüntüle",
    Danish: "Se Tidligere Trækninger",
    Norwegian: "Se Tidligere Trekk",
    Finnish: "Katso Aiemmat Nostot",
    Greek: "Προβολή Προηγούμενων Τραβηγμάτων"
  },
  completeCaptchaFirst: {
    English: "Please complete the captcha first",
    Spanish: "Por favor, complete el captcha primero",
    French: "Veuillez d'abord compléter le captcha",
    German: "Bitte zuerst das Captcha ausfüllen",
    Italian: "Per favore completa prima il captcha",
    Japanese: "最初にキャプチャを完了してください",
    Chinese: "请先完成验证码",
    Russian: "Пожалуйста, сначала пройдите капчу",
    Portuguese: "Por favor, complete o captcha primeiro",
    Dutch: "Voltooi eerst de captcha",
    Korean: "먼저 캡차를 완료해주세요",
    Arabic: "يرجى إكمال اختبار كابتشا أولاً",
    Hindi: "कृपया पहले कैप्चा पूरा करें",
    Swedish: "Vänligen slutför captcha först",
    Polish: "Proszę najpierw wypełnić captcha",
    Turkish: "Lütfen önce captcha'yı tamamlayın",
    Danish: "Venligst udfyld captcha først",
    Norwegian: "Vennligst fullfør captcha først",
    Finnish: "Ole hyvä ja täytä captcha ensin",
    Greek: "Παρακαλώ ολοκληρώστε πρώτα το captcha"
  },
  draw: {
    English: "Draw",
    Spanish: "Tirar",
    French: "Tirer",
    German: "Ziehen",
    Italian: "Pesca",
    Japanese: "ドロー",
    Chinese: "抽牌",
    Russian: "Разложить",
    Portuguese: "Tirar",
    Dutch: "Trek",
    Korean: "드로우",
    Arabic: "اسحب",
    Hindi: "कार्ड निकालें",
    Swedish: "Dra",
    Polish: "Wyciągnij",
    Turkish: "Çek",
    Danish: "Træk",
    Norwegian: "Trekk",
    Finnish: "Nosta",
    Greek: "Τράβηξε"
  },
  processing: {
    English: "Processing...",
    Spanish: "Procesando...",
    French: "Traitement en cours...",
    German: "Verarbeiten...",
    Italian: "Elaborazione in corso...",
    Japanese: "処理中...",
    Chinese: "处理中...",
    Russian: "Обработка...",
    Portuguese: "Processando...",
    Dutch: "Verwerken...",
    Korean: "처리 중...",
    Arabic: "معالجة...",
    Hindi: "प्रोसेसिंग...",
    Swedish: "Bearbetar...",
    Polish: "Przetwarzanie...",
    Turkish: "İşleniyor...",
    Danish: "Behandler...",
    Norwegian: "Behandler...",
    Finnish: "Käsitellään...",
    Greek: "Επεξεργασία..."
  },
  drawing: {
    English: "Drawing...",
    Spanish: "Sacando...",
    French: "Tirage...",
    German: "Ziehe...",
    Italian: "Pescando...",
    Japanese: "ドロー中...",
    Chinese: "抽牌中...",
    Russian: "Разложение...",
    Portuguese: "Tirando...",
    Dutch: "Trekken...",
    Korean: "드로우 중...",
    Arabic: "سحب...",
    Hindi: "ड्रा कर रहा है...",
    Swedish: "Drar...",
    Polish: "Wyciąganie...",
    Turkish: "Çekiliyor...",
    Danish: "Trækker...",
    Norwegian: "Trekker...",
    Finnish: "Nostamassa...",
    Greek: "Τραβώντας..."
  },
  language: {
    English: "Language",
    Spanish: "Idioma",
    French: "Langue",
    German: "Sprache",
    Italian: "Lingua",
    Portuguese: "Idioma",
    Russian: "Язык",
    Chinese: "语言",
    Japanese: "言語",
    Korean: "언어",
    Dutch: "Taal",
    Polish: "Język",
    Swedish: "Språk",
    Turkish: "Dil",
    Danish: "Sprog",
    Norwegian: "Språk",
    Finnish: "Kieli",
    Greek: "Γλώσσα",
    Arabic: "اللغة",
    Hindi: "भाषा",
  },
  languageComingSoon: {
    English: "Language selection coming soon",
    Spanish: "Selección de idioma próximamente",
    French: "Sélection de la langue bientôt disponible",
    German: "Sprachauswahl in Kürze verfügbar",
    Italian: "Selezione della lingua in arrivo",
    Japanese: "言語選択は近日公開予定",
    Chinese: "语言选择即将推出",
    Russian: "Выбор языка скоро будет доступен",
    Portuguese: "Seleção de idioma em breve",
    Dutch: "Taalselectie binnenkort beschikbaar",
    Korean: "언어 선택 기능 곧 제공 예정",
    Arabic: "اختيار اللغة قريبًا",
    Hindi: "भाषा चयन जल्द ही आ रहा है",
    Swedish: "Språkval kommer snart",
    Polish: "Wybór języka wkrótce",
    Turkish: "Dil seçimi yakında",
    Danish: "Sprogvalg kommer snart",
    Norwegian: "Språkvalg kommer snart",
    Finnish: "Kielen valinta tulossa pian",
    Greek: "Η επιλογή γλώσσας έρχεται σύντομα",
  },
  threeCardSpread: {
    English: "Three Card Spread",
    Spanish: "Tirada de Tres Cartas",
    French: "Tirage de Trois Cartes",
    German: "Drei-Karten-Legung",
    Italian: "Stesura di Tre Carte",
    Japanese: "3枚のカード展開",
    Chinese: "三张牌阵",
    Russian: "Расклад Трёх Карт",
    Portuguese: "Tiragem de Três Cartas",
    Dutch: "Drie Kaarten Legging",
    Korean: "세 장의 카드 스프레드",
    Arabic: "انتشار ثلاث بطاقات",
    Hindi: "तीन कार्ड स्प्रेड",
    Swedish: "Tre Korts Spridning",
    Polish: "Rozkład Trzech Kart",
    Turkish: "Üç Kart Açılımı",
    Danish: "Tre Kort Spredning",
    Norwegian: "Tre Kort Spredning",
    Finnish: "Kolmen Kortin Levitys",
    Greek: "Απλωμα Τριών Καρτών"
  },
  celticCrossSpread: {
    English: "Celtic Cross Spread",
    Spanish: "Tirada de la Cruz Celta",
    French: "Tirage de la Croix Celtique",
    German: "Keltisches Kreuz Legung",
    Italian: "Stesura della Croce Celtica",
    Japanese: "ケルトクロス展開",
    Chinese: "凯尔特十字牌阵",
    Russian: "Кельтский Крест",
    Portuguese: "Cruz Celta",
    Dutch: "Keltisch Kruis Legging",
    Korean: "켈틱 크로스 스프레드",
    Arabic: "انتشار الصليب السلتي",
    Hindi: "सेल्टिक क्रॉस स्प्रेड",
    Swedish: "Keltiskt Kors Spridning",
    Polish: "Krzyż Celtycki",
    Turkish: "Kelt Haçı Açılımı",
    Danish: "Keltisk Kors Spredning",
    Norwegian: "Keltisk Kors Spredning",
    Finnish: "Kelttiläinen Risti Levitys",
    Greek: "Κελτικός Σταυρός"
  },
  errorMessage: {
    English: "An error occurred while processing your request.",
    Spanish: "Se produjo un error al procesar su solicitud.",
    French: "Une erreur s'est produite lors du traitement de votre demande.",
    German: "Ein Fehler ist aufgetreten, während Ihre Anfrage verarbeitet wurde.",
    Italian: "Si è verificato un errore durante l'elaborazione della sua richiesta.",
    Japanese: "要求の処理中にエラーが発生しました。",
    Chinese: "处理请求时发生错误。",
    Russian: "При обработке вашего запроса произошла ошибка.",
    Portuguese: "Ocorreu um erro ao processar sua solicitação.",
    Dutch: "Er is een fout opgetreden tijdens het verwerken van uw verzoek.",
    Korean: "요청 처리 중 오류가 발생했습니다.",
    Arabic: "حدثت مشكلة أثناء معالجة طلبك.",
    Hindi: "आपके अनुरोध के प्रोसेसिंग के दौरान एक त्रुटि हुई।",
    Swedish: "Ett fel uppstod när din begäran bearbetades.",
    Polish: "Wystąpił błąd podczas przetwarzania Twojego żądania.",
    Turkish: "İsteğiniz işlenirken bir hata oluştu.",
    Danish: "Der opstod en fejl, mens din anmodning blev behandlet.",
    Norwegian: "Det oppstod en feil under behandlingen av din forespørsel.",
    Finnish: "Virhe tapahtui, kun pyyntösi käsiteltiin.",
    Greek: "Προέκυψε σφάλμα κατά την επεξεργασία της αίτησής σας."
  },
  inputPlaceholder: {
    English: "Enter your question...",
    Spanish: "Ingrese su pregunta...",
    French: "Entrez votre question...",
    German: "Geben Sie Ihre Frage ein...",
    Italian: "Inserisci la tua domanda...",
    Japanese: "質問を入力してください...",
    Chinese: "请输入您的提问...",
    Russian: "Введите ваш вопрос...",
    Portuguese: "Digite sua pergunta...",
    Dutch: "Voer uw vraag in...",
    Korean: "질문을 입력하세요...",
    Arabic: "أدخل سؤالك...",
    Hindi: "अपना प्रश्न दर्ज करें...",
    Swedish: "Ange din fråga...",
    Polish: "Wpisz swoje pytanie...",
    Turkish: "Sorunuzu girin...",
    Danish: "Indtast dit spørgsmål...",
    Norwegian: "Skriv inn spørsmålet ditt...",
    Finnish: "Kirjoita kysymyksesi...",
    Greek: "Εισαγάγετε την ερώτησή σας..."
  },
  drawCardsAriaLabel: {
    English: "Draw Tarot Cards",
    Spanish: "Sacar Cartas del Tarot",
    French: "Tirer les Cartes de Tarot",
    German: "Tarotkarten ziehen",
    Italian: "Pesca Carte dei Tarocchi",
    Portuguese: "Tirar Cartas de Tarot",
    Russian: "Вытянуть карты Таро",
    Chinese: "抽塔罗牌",
    Japanese: "タロットカードを引く",
    Korean: "타로 카드 뽑기",
    Dutch: "Trek Tarotkaarten",
    Polish: "Wyciągnij karty tarota",
    Swedish: "Dra Tarotkort",
    Turkish: "Tarot Kartları Çek",
    Danish: "Træk Tarotkort",
    Norwegian: "Trekk Tarotkort",
    Finnish: "Nosta Tarot-kortit",
    Greek: "Τράβηξε Κάρτες Ταρώ",
    Arabic: "اسحب بطاقات التارو",
    Hindi: "टैरो कार्ड खींचें",
  },
};

// Translation context and hooks
let currentLanguage = 'English';
let translations = buttonTranslations;

export const getFullLanguageName = (shortName) => {
  return languageNames[shortName] || shortName;
};

export const loadTranslations = async (language) => {
  currentLanguage = language;
  return buttonTranslations;
};

export const initializeTranslations = () => {
  const savedLanguage = localStorage.getItem('selectedLanguage') || 'English';
  currentLanguage = savedLanguage;
  return loadTranslations(savedLanguage);
};

export const useTranslation = () => {
  const [language, setLanguage] = useState(currentLanguage);

  const getTranslation = useCallback((key, defaultValue = '') => {
    if (!translations[key]) {
      return defaultValue;
    }
    return translations[key][language] || translations[key]['English'] || defaultValue;
  }, [language]);

  const changeLanguage = useCallback(async (newLanguage) => {
    currentLanguage = newLanguage;
    localStorage.setItem('selectedLanguage', newLanguage);
    translations = await loadTranslations(newLanguage);
    setLanguage(newLanguage);
  }, []);

  return {
    language,
    changeLanguage,
    getTranslation,
    getFullLanguageName
  };
};
