import React from 'react';

const MaintenanceBanner = () => {
  return (
    <div className="w-full bg-yellow-500 text-black p-4 text-center font-medium shadow-md">
      🔧 We are currently performing maintenance. Some features (all) may be temporarily unavailable.
    </div>
  );
};

export default MaintenanceBanner;
